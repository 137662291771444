<template>

  <div id="app" class='searcher'>
    <div class='searcher__drop-bg' v-if='isShown'>
      <div class='searcher__drop' v-click-outside="onClickOutside">

        <div class='searcher__header'>
          <aside>
            <template v-if='companyId && (isType != "Company")'>
              <a href='#' class='active' v-if='only_company' @click.prevent.stop="changeLimitCompany"><i class='fas fa-check icon'></i>Within {{companyName}}</a>
              <a href='#' v-if='!only_company' @click.prevent.stop="changeLimitCompany">Within {{companyName}}</a>
            </template>

            <a href='#' v-if='include_archived' class='active' @click.prevent.stop="changeIncludeArchived"><i class='fas fa-check icon'></i>Museum</a>
            <a href='#' v-if='!include_archived' @click.prevent.stop="changeIncludeArchived">Museum</a>

            <a href='#' v-if='search_by_all' class='active' @click.prevent.stop="changeSearchByAll"><i class='fas fa-check icon'></i>Comprehensive</a>
            <a href='#' v-if='!search_by_all' @click.prevent.stop="changeSearchByAll">Comprehensive</a>
            <a href='#' v-if='search_by_exact' class='active' @click.prevent.stop="changeSearchByExact"><i class='fas fa-check icon'></i>Exact</a>
            <a href='#' v-if='!search_by_exact' @click.prevent.stop="changeSearchByExact">Exact</a>
          </aside>

        </div>
        <div v-if='!isLoaded' class="searcher__loading-container">
          <mwc-circular-progress indeterminate></mwc-circular-progress>
        </div>

        <div v-if='isLoaded' class='searcher__columns'>
          <section v-if='companyQuickHop'>
            <header>
              <h3><i class='fas fa-store-alt icon'></i>{{structureName}}</h3>
            </header>
            <main>
              <ul v-if='(company_results.length > 0)' v-show='company_loaded'>
                <li v-for='r in company_results'><a :href='r.url'>{{r.name}}</a></li>
              </ul>
              <p class='searcher__blank' v-if='company_results.length === 0 && company_loaded'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            </main>
          </section>
          <section v-if='!companyQuickHop'>
            <header>
              <h3><i class='fas fa-briefcase icon'></i>{{structureName}}</h3>
            </header>
            <main>
              <ul v-if='(company_results.length > 0)' v-show='company_loaded && !only_company'>
                <li v-for='r in company_results'><a :href='r.url'>{{r.name}}</a></li>
              </ul>
              <p class='searcher__blank' v-if='company_results.length === 0 && company_loaded && !only_company'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
              <p class='searcher__blank' v-if='only_company'>No results with filter.</p>
            </main>
          </section>
          <section>
            <header>
              <h3><i class='fas fa-circle icon'></i>Assets</h3>
            </header>
            <main>
              <ul>
                <ul v-if='(asset_results.length > 0)' v-show='asset_loaded'>
                  <li v-for='r in asset_results'><a :href='r.url'>{{r.name}}<span>{{r.asset_type}} / {{r.company_name}}</span></a></li>
                </ul>
                <p class='searcher__blank' v-if='asset_results.length === 0 && asset_loaded'>
                No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
              </ul>
            </main>
          </section>
          <section>
            <header>
              <h3><i class='fas fa-key icon'></i>Passwords</h3>
            </header>
            <main>
              <ul>
                <ul v-if='password_results.length > 0' v-show='password_loaded'>
                  <li v-for='r in password_results'><a :href='r.url'>{{r.name}}<span><template v-if='r.asset_name'>{{r.asset_name}} / </template>{{r.company_name}}</span></a></li>
                </ul>
                <p class='searcher__blank' v-if='password_results.length === 0 && password_loaded'>
                No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
              </ul>
            </main>
          </section>
          <section>
            <header>
              <h3><i class='fas fa-newspaper icon'></i>Articles</h3>
            </header>
            <main>
              <ul>
                <ul v-if='article_results.length > 0' v-show='article_loaded'>
                  <li v-for='r in article_results'><a :href='r.url'>{{r.name}}<span v-if='r.company_name'>{{r.company_name}}</span></a></li>
                </ul>
                <p class='searcher__blank' v-if='article_results.length === 0 && article_loaded'>
                No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
              </ul>
            </main>
          </section>
          <section>
            <header>
              <h3><i class='fas fa-globe icon'></i>Websites</h3>
            </header>
            <main>
              <ul>
                <ul v-if='website_results.length > 0' v-show='website_loaded'>
                  <li v-for='r in website_results'><a :href='r.url'>{{r.name}}<span v-if='r.company_name'>{{r.company_name}}</span></a></li>
                </ul>
                <p class='searcher__blank' v-if='website_results.length === 0 && website_loaded'>
                No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
              </ul>
            </main>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vClickOutside from 'v-click-outside'

export default {
  props: {
    structureName: String,
    companyQuickHop: Boolean,
    companyName: String,
    companyId: Number
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    isLoaded () {
      return !this.isLoading
    }
  },
  watch: {
    q () {
      this.onInput()
    }
  },
  mounted () {
    var that = this;
    document.querySelector("#mainSearchInput").addEventListener("input", function (e) {
      that.q = e.target.value;
      that.isShown = true;
    })
    this._keyListener = function(e) {
      if (e.key === "/" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.
        this.isShown = true;
        this.$nextTick(() => document.querySelector("#mainSearchInput").focus())
      } else if (e.key === "Escape") {
        e.preventDefault();
        this.$nextTick(() => document.querySelector("#mainSearchInput").blur())
        this.isShown = true;

      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  methods: {
    onClickOutside () {
      this.isShown = false;
    },
    changeLimitCompany () {
      this.only_company = !this.only_company;
      this.onInput()
    },
    changeIncludeArchived () {
      this.include_archived = !this.include_archived;
      this.onInput()
    },
    changeSearchByAll () {
      this.search_by_all = !this.search_by_all;
      this.onInput()
    },
    changeSearchByExact () {
      this.search_by_exact = !this.search_by_exact;
      this.onInput()
    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    onInput() {
      var that = this;
      // Start loading animation
      this.isLoading = true;
      // Clear the existing timeout to reset the debouncing timer
      clearTimeout(that.debounceTimeout);

      // Set a new timeout
      that.debounceTimeout = setTimeout(() => {
        that.company_loaded = false;
        that.asset_loaded = false;
        that.password_loaded = false;
        that.article_loaded = false;
        that.website_loaded = false;

        // that.toggle(2);

        axios.get('/searches', {
          params: {
            q: that.q,
            type: "Company",
            company: that.only_company ? that.companyId : "",
            include_archived: that.include_archived,
            search_by_all: that.search_by_all,
            search_by_exact: that.search_by_exact
          }
        }).then(function (response) {
          that.company_loaded = true;
          that.company_results = response.data;
        });

        axios.get('/searches', {
          params: {
            q: that.q,
            type: "Asset",
            company: that.only_company ? that.companyId : "",
            include_archived: that.include_archived,
            search_by_all: that.search_by_all,
            search_by_exact: that.search_by_exact
          }
        }).then(function (response) {
          that.asset_loaded = true;
          that.asset_results = response.data;
        });

        axios.get('/searches', {
          params: {
            q: that.q,
            type: "Password",
            company: that.only_company ? that.companyId : "",
            include_archived: that.include_archived,
            search_by_all: that.search_by_all,
            search_by_exact: that.search_by_exact
          }
        }).then(function (response) {
          that.password_loaded = true;
          that.password_results = response.data;
        });

        axios.get('/searches', {
          params: {
            q: that.q,
            type: "Article",
            company: that.only_company ? that.companyId : "",
            include_archived: that.include_archived,
            search_by_all: that.search_by_all,
            search_by_exact: that.search_by_exact
          }
        }).then(function (response) {
          that.article_loaded = true;
          that.article_results = response.data;
        });

        axios.get('/searches', {
          params: {
            q: that.q,
            type: "Website",
            company: that.only_company ? that.companyId : "",
            include_archived: that.include_archived,
            search_by_all: that.search_by_all,
            search_by_exact: that.search_by_exact
          }
        }).then(function (response) {
          that.website_loaded = true;
          that.website_results = response.data;
        });

        // Stop loading animation
        this.isLoading = false;

      }, 500);
    },

    switchType () {
      this.$nextTick(() => this.$refs['searchInput'].focus())
      this.onInput()
    },
  },
  data: function () {
    return {
      isShown: false,
      q: "",
      company_results: [],
      asset_results: [],
      password_results: [],
      article_results: [],
      website_results: [],
      include_archived: false,
      company_loaded: true,
      asset_loaded: true,
      password_loaded: true,
      article_loaded: true,
      password_loaded: true,
      website_loaded: true,
      search_by_all: false,
      search_by_exact: false,
      arrowCounter: -1,
      only_company: this.companyId ? true : false,
      results: [],
      isType: this.companyId ? "Asset" : "Company",
      debounceTimeout: null,
      isLoading: false,
    }
  }
}
</script>

